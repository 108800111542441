const slick = require('/assets/js/lib/slick/slick.js');
import '/assets/js/common.js';
import '/assets/js/sorano.template.js';
import '/assets/js/lib/slick/slick.css';
import '/assets/css/_common.scss';
import '/assets/css/top.scss';


document.addEventListener("DOMContentLoaded", function () {
  let mainvCount = 0;

  var elementMainv = document.querySelectorAll('.mainv__imageItem');

  var elementMainvArr = Array.prototype.slice.call(elementMainv);

  elementMainvArr.forEach(function (items, key) {
    items.classList.add('mainv__imageItem--' + key);
  });

  $(".mainv__imageItem--" + mainvCount).addClass('mainv__imageItem--active');

  const mainvCountUp = () => {
    mainvCount++

    if (mainvCount == (elementMainvArr.length)) {
      mainvCount = 0
    }

    $(".mainv__imageItem").removeClass('mainv__imageItem--active');
    $(".mainv__imageItem--" + mainvCount).addClass('mainv__imageItem--active');
  }
  setInterval(mainvCountUp, 4500);



  /** ②指が触れたか検知 */
  $(".pickup__list").on("touchstart", start_check);

  /** ③指が動いたか検知 */
  $(".pickup__list").on("touchmove", move_check);

  /** ④指が離れたか検知 */
  $(".pickup__list").on("touchend", end_check);

  /** 変数宣言 */
  var moveY, moveX, posiY, posiX;

  var msgY = '';
  var msgX = '';

  // ⑤タッチ開始時の処理
  function start_check(event) {
    /** 現在の座標取得 */
    posiY = getY(event);
    posiX = getX(event);

    /** 移動距離状態を初期化 */
    moveY = '';
    moveX = '';

    /** 表示メッセージを初期化 */
    msgY = '';
    msgX = '';
  }

  // ⑥スワイプ中の処理
  let moveActive = 0
  function move_check(event) {
    console.log(moveActive)
    if (moveActive < 2) {
      moveActive++

      if (posiX - getX(event) > 20) // 20px以上移動でスワイプと判断
      {
        /** 右→左と判断 */
        moveX = "left";
        nowSlideNum = nowSlideNum + 1
        if (nowSlideNum >= pickupSlideLength) {
          nowSlideNum = 0
        }
        changePickupSlide(nowSlideNum, widthOfOnePickUpItem, marginOfOnePickUpItem)
        console.log("左")
        console.log(nowSlideNum)
      } else if (posiX - getX(event) < -20) // 20px以上移動でスワイプと判断
      {
        /** 左→右と判断 */
        moveX = "right";
        if (nowSlideNum !== 0) {
          nowSlideNum = nowSlideNum - 1
          changePickupSlide(nowSlideNum, widthOfOnePickUpItem, marginOfOnePickUpItem)
        }
        console.log(nowSlideNum)
        console.log("右")
      }
    }

    setTimeout(function () {
      moveActive = 0
    }, 350);
  }

  // ⑦指が離れた時の処理
  function end_check(event) {
    if (moveX == "left") {
      msgX = "left";
    } else if (moveX == "right") {
      msgX = "right";
    }
  }


  // 座標取得処理
  function getY(event) {
    //縦方向の座標を取得
    return (event.originalEvent.touches[0].pageY);
  }

  function getX(event) {
    //横方向の座標を取得
    return (event.originalEvent.touches[0].pageX);
  }
});


// ===========================================
// バナーリストのスライドショー
// ===========================================
$(function (){
  $('.pickup__list').each(function(){
    const slide = $(this),
          dots = slide.next('.slideDotsArea');

    slide.slick({
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      slidesToShow: 3,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 400,
      dots: true,
      appendDots: dots,
      responsive: [
        {
          breakpoint: 737,
          settings: {
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            slidesToShow: 1,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 400,
            dots: true,
            appendDots: dots,
          },
        },
      ],
    });
  });
});

// ===========================================
// メインビジュアルの虹の表現
// ===========================================
$(function (){
  $('.mainv__textBox').addClass('mainv__textBox--active');
  $('.mainv__anime').addClass('mainv__anime--active');
  $('.mainv__imageBox').addClass('mainv__imageBox--active');
});


// ===========================================
// スクロールアニメーションの関数
// ===========================================
function scrollAnimation(data) {
  function scrollAnimationBase(data) {
    let scroll = $(window).scrollTop(),
        windowHeight = $(window).height();
    $("." + data.className).each(function () {
      const _this = $(this);
      let position = _this.offset().top,
          minusValue = windowHeight - data.triggerPoint;

      if (scroll > position - minusValue) {
        _this.addClass(data.className + "--active");
      }
    });
  }

  $(window).on("load scroll", function () {
    scrollAnimationBase(data);
  });
}

// ===========================================
// スクロールアニメーションのオプション
// ===========================================
scrollAnimation({
  className: "intro",
  triggerPoint: 150,
});

scrollAnimation({
  className: "jigyo",
  triggerPoint: 150,
});

scrollAnimation({
  className: "ir",
  triggerPoint: 150,
});

scrollAnimation({
  className: "sas",
  triggerPoint: 150,
});

scrollAnimation({
  className: "recruit",
  triggerPoint: 150,
});

scrollAnimation({
  className: "group",
  triggerPoint: 150,
});