// ===========================================
// 外部リンクアラートの処理
// ===========================================
$(function () {
  $('a').each(function(){
    // 配列のalertListは、ヘッダー部分で定義しています。

    const aTag = $(this),
          href = aTag.attr('href');

    alertList.forEach(function( alertItem ){
      if( href === alertItem.link ) {
        aTag.attr('data-out-link-alert', alertItem.name);
      }
    });
  });

  $('a[data-out-link-alert]').on('click', function(){
    const alertSiteName = $(this).attr('data-out-link-alert'),
          outLink = window.confirm('ここから先は、' + alertSiteName + 'のWEBサイトです。\n移動しますか？'),
          link = $(this).attr('href');

    if( outLink === true ) {
      window.open( link, '_blank' );
    }

    return false;
  });
});

$(document).ready(function(){
  // nav
  $(".menu__button").click(function () {
    this.classList.toggle("active");

    $(".header__navBox").toggleClass("header__navBox--active");

    $(".header__inner").toggleClass("header__inner--white");
  });


  $(".header__navLink--haveChild").click(function () {
    $(this).toggleClass("openactive");
    $(this).children(".header__navChildList").toggleClass("active");
  });

  $(".header__navLink--idLink").click(function () {
    $(".menu__button").toggleClass("active");

    $(".header__navBox").toggleClass("header__navBox--active");

    $(".header__inner").toggleClass("header__inner--white");
  });



  $(".footNav__listTitleLinkWrap--haveChild").click(function () {
    $(this).next(".footNav__list").toggleClass("openactive");
    $(this).children(".footNav__listTitleLink").toggleClass("openactive");
  });




  $(".ir__navListLink--haveChild").click(function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(".ir__navListLink--haveChild").removeClass("active");
      $(this).addClass("active");
    }
  });

  $(document).click(function(event) {
    if(!$(event.target).closest('.irNav__box').length) {
      $(".ir__navListLink--haveChild").removeClass("active");
    }
  });


  $('select[name=languageSwitcher]').change(function() {
    var langVal = $(this).val();

    if (langVal == 'Japanese') {
      window.location.href = "/"
    } else if (langVal == 'English') {
      window.location.href = "/english/"
    }
  });


  // setTimeout(function () {
  // }, 2000);
});

$('a[href^="#"]').click(function () {
  var speed = 800;
  var href = $(this).attr("href");

  var target = $(href == "#" || href == "" ? "html" : href);
  var position = target.offset().top - 100;
  $("html, body").animate({
    scrollTop: position
  }, speed, "swing");
  return false;
});

$(window).scroll(function (){
  if ($(window).scrollTop() > 0) {
    $('.header__inner').addClass('active');
  } else {
    $('.header__inner').removeClass('active');
  }

  if ($(window).scrollTop() > 100) {
    $('.irNav__inner').addClass('active');
  } else {
    $('.irNav__inner').removeClass('active');
  }

  if ($(window).scrollTop() > 180) {
    // $('.irNav__inner').addClass('childHideSp');
    $(".ir__navListLink--haveChild").removeClass("active");
  } else {
    // $('.irNav__inner').removeClass('childHideSp');
  }
});


// ===========================================
// テーブルパーツのスクロール処理
// ===========================================
$(function () {
  $('.table__wrapBox').each(function(){
    console.log('hoge');
    const wrapper = $(this);

    if( wrapper.find('.scrollTable').length ) {
      wrapper.addClass('table__wrapBox--hasInnerScroll');
    }
  });
});